body {
  margin: 0;
}

h1 {
  margin: 0!important;
}

.app-logo {
  margin-bottom: 32px;

  img {
    max-width: 100%;
  }
}

.app-logo-small {
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  margin-right: 32px;
}

.app-content {
  padding: 24px;
  min-height: 100vh;
}

.login-recover {
  text-align: right;
  margin-bottom: 16px;
}

.error {
  margin-bottom: 16px;
}

.container {
  margin: 32px;

  .title {
    text-align: left;
    margin-bottom: 48px!important;
  }
}

.container-small {
  margin: auto;
  max-width: 500px;

  .title {
    text-align: center;
    margin-bottom: 48px!important;
  }
}

.schedule {
  display: flex;
  overflow-y: scroll;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;

  & > div {
    padding: 0 4px;
    flex: 0 0 200px;
    border-right: 1px solid black;

    &.today {
      background: rgb(226, 246, 255);
    }
  }

  & > div > b {
    display: block;
    text-align: center;
    margin: 0 -4px 0 -4px;
    padding: 16px 0;
    border-bottom: 1px solid black;
    position: sticky;
    top: 0px;
  }
}

.slot {
  background: white;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid black;
  border-left: 4px solid black;
  position: relative;
  height: 100px;
  overflow: hidden;

  &:hover {
    background: #efefef !important;
  }

  &.empty {
    border: none;
    background: none;
  }

  .slot-category {
    margin: 0 0 4px 0;
    font-weight: 900;
  }

  .slot-specifier {
    margin: 0 0 4px 0;
    font-size: 12px;
    font-weight: 300;
  }

  .slot-student {
    margin: 0 0 4px 0;
    font-weight: 600;
  }

  .slot-absent {
    margin: 0 0 4px 0;
    font-size: 12px;
    font-weight: 900;
    color: red;
  }

  .slot-takeover {
    margin: 0 0 4px 0;
    font-size: 12px;
    font-style: oblique;
    font-weight: 600;
    color: blue;
  }

  .slot-actions {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}

.line {
  border-bottom: 1px solid black;
  margin: 8px -4px 0 -4px;
}

.matrix {
  .matrix-label {
    fill: black;
    font-size: 14px;
    font-weight: 300;
  }

  .matrix-cell {
    opacity: 0.5;
  }

  .matrix-text {
    fill: black;
    font-size: 12px;
    font-weight: 600;
  }
}

.ant-menu-item-divider {
  margin: 16px!important;
  border-top: 1px solid rgba(255, 255, 255, 0.2)!important;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.sheet {
  .box {
    border: solid 2px #810065;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;

    &.gray {
      border-color: #ccc;
    }

    &.blue {
      border-color: blue;
    }

    &.green {
      border-color: green;
    }

    &.red {
      border-color: red;
    }
  }

  p {
    margin: 0 0 16px 0;
  }

  hr {
    margin: 32px 0;
  }

  label, b {
    font-weight: 700;
  }

  table, th, td {
    border: solid 1px #810065;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 16px;
  }

  td, th {
    padding: 8px 16px;
  }

  thead th {
    font-weight: 900;
  }

  td {
    font-size: 90%;
  }

  tr.highlighted {
    background-color: #ececec;
  }
}

.department {
  padding: 16px;
  max-width: 300px;
}

.department-title {
  margin: 0 0 16px 0;
  font-size: 16px;
  color: #fff;
}

.department-sheets {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.department-button {
  background: none;
  border: none;
  text-align: left;
  color: #fff;
  cursor: pointer;
}

.res-table {
  margin-bottom: 32px;

  table, td {
    border: solid 1px #810065;
  }

  table {
    border-collapse: collapse;
  }

  td {
    padding: 8px 16px;
  }
}